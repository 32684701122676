import EmptyLayout from './EmptyLayout';
import Layout from './Layout';
const LayoutConfig = [
  {
    path: '/users',
    layout: Layout,
  },
  {
    path: '/invoices',
    layout: Layout,
  },
  {
    path: '/credit-note',
    layout: Layout,
  },
  {
    path: '/clients',
    layout: Layout,
  },
  {
    path: '/requirements',
    layout: Layout,
  },
  {
    path: '/companies',
    layout: Layout,
  },
  {
    path: '/bulk-upload',
    layout: Layout,
  },
  {
    path: '/gstNumbers',
    layout: Layout,
  },
  {
    path: '/vendor',
    layout: Layout,
  },
  {
    path: '/purchase-order',
    layout: Layout,
  },
  {
    path: '',
    layout: EmptyLayout,
  },
];

const getLayout = (path) => {
  let config = LayoutConfig.find((config) => path.includes(config.path));
  if (config) return config.layout;
  else return EmptyLayout;
};

export { getLayout };
