/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { changePassword } from '@services/user.service';
import { useEffect, useState, useRef } from 'react';

const initialValues = {
  newPassword: '',
  confirmPassword: '',
};

const ChangePassword = ({ closeEdit, auth, setToast }) => {
  const [password, setPassword] = useState(initialValues);
  const wrapperRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleInputChange = (e) => {
    setErrorMessage('');
    const { name, value } = e.target;
    setPassword({
      ...password,
      [name]: value,
    });
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      closeEdit();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);

  const handleUpdatePassword = async () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,14}$/;
    // Extract password fields for clarity
    const { newPassword, confirmPassword } = password;
   
    if (!newPassword || !confirmPassword) {
      setErrorMessage('Please enter the new password.');
      return;
    }

    if (!passwordPattern.test(newPassword)) {
      setErrorMessage(
        'Password must be 12-14 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.'
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords don't match.");
      return;
    }
    const userInfo = {
      id: auth.id,
      password: newPassword,
    };
    try {
      const response = await changePassword(userInfo);
      if (response.status) {
        closeEdit();
        setToast(
          <div className='flex items-center justify-center text-lg'>
            <FontAwesomeIcon icon='check' className='mr-2' />
            <span>Password changed successfully.</span>
          </div>
        );
      } else {
        setErrorMessage(response.message);
      }
    } catch (error) {
      setErrorMessage(
        'An error occurred while changing the password. Please try again.'
      );
    }
  };

  return (
    <div className='text-slate-gray fixed z-50 bg-black  w-screen h-screen top-0 left-0 backdrop-filter backdrop-blur-lg bg-opacity-60 flex items-center justify-center'>
      <div
        className=' lg:w-1/2 bg-white absolute  rounded-lg p-3 pb-10 px-10'
        ref={wrapperRef}
      >
        <div className='flex justify-between m-3 items-center'>
          <span>CHANGE PASSWORD</span>
          <button onClick={() => closeEdit()}>X</button>
        </div>
        <hr></hr>
        <div className='flex flex-col justify-evenly h-5/6'>
          <div className='relative flex flex-col sm:m-3 sm:mb-4 items-start w-full lg:two-grid pr-10'>
            <label className='mb-2'>New Password*</label>
            <input
              type='password'
              placeholder='Enter Password'
              className=' w-full px-2 py-0.5 placeholder-gray-500 text-gray-600 relative bg-white rounded-lg text-base border shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
              value={password.newPassword}
              name='newPassword'
              onChange={handleInputChange}
            />
          </div>
          <div className='relative flex flex-col sm:m-3 sm:mb-4 items-start w-full lg:two-grid pr-10'>
            <label className='mb-2'>Confirm Password</label>
            <input
              type='password'
              placeholder='Enter Password'
              className=' w-full px-2 py-0.5 placeholder-gray-500 text-gray-600 relative bg-white rounded-lg text-base border shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150'
              value={password.confirmPassword}
              name='confirmPassword'
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='flex flex-col items-center justify-center mb-2'>
          <button
            type='button'
            className='border rounded-lg w-24 my-2 mx-1 text-center text-white bg-dark-blue sm:m-3 lg:m-4 '
            onClick={() => {
              handleUpdatePassword();
            }}
          >
            Save
          </button>
          <span className='self-center text-red-700 font-light text-center'>
            {errorMessage}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
