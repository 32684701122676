import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getClientRequiremenetFilter = () => {
  const auth = cookies.get('clientRequirementFilter');
  return auth
}

export const setClientRequiremenetFilter = (authObject) => {
  cookies.set('clientRequirementFilter', JSON.stringify(authObject), { path: '/' });
  return authObject;
}

export const removeClientRequiremenetFilter = () => {
  cookies.remove('clientRequirementFilter', { path: '/' });
  return;
}

export const getRequiremenetFilter = () => {
  const auth = cookies.get('requirementFilter');
  return auth
}

export const setRequiremenetFilter = (authObject) => {
  cookies.set('requirementFilter', JSON.stringify(authObject), { path: '/' });
  return authObject;
}

export const removeRequiremenetFilter = () => {
  cookies.remove('requirementFilter', { path: '/' });
  return;
}



