/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import "../styles/globals.css";
import { appWithTranslation } from "next-i18next";
import nextI18NextConfig from "../next-i18next.config.js";
import WithAuth from "@components/Auth/WithAuth";
import { getLayout } from "@components/Layout/LayoutConfiguration";

function MyApp({ Component, pageProps, router }) {
  let layout = getLayout(router.pathname);
  return (
    <WithAuth router={router}>
      {layout({ children: <Component {...pageProps} /> })}
    </WithAuth>
  );
}

export default appWithTranslation(MyApp, nextI18NextConfig);

