export const isProtected = (route) => {
  let unprotectedRoutes = ['/', '/reset-password', '/reset-password/verify'];
  return !unprotectedRoutes.includes(route);
};

export const KAM = (route) => {
  let unAccessibleRoutes = [
    '/gstNumbers/[gstId]/edit',
    '/gstNumbers',
    '/gstNumbers/new',
    '/users',
    '/users/new',
    '/users/[id]/edit',
  ];
  return !unAccessibleRoutes.includes(route);
};

export const ADMIN = (route) => {
  let unAccessibleRoutes = []; //INFO: admin can access all routes
  return !unAccessibleRoutes.includes(route);
};

export const GROUND_TEAM = (route) => {
  let unAccessibleRoutes = [
    '/companies',
    '/companies/[companyId]/clients',
    '/companies/[companyId]/clients/new',
    '/companies/[companyId]/clients/[clientId]/edit',
    '/requirements/[id]/edit',
    '/requirements/[id]/fulfilments',
    '/requirements/[id]/fulfilments/invoice/new',
    '/requirements/[id]/fulfilments/new',
    '/requirements/[id]/fulfilments/[fulfilmentId]/edit',
    '/requirements/[id]/vendorservices',
    '/requirements/[id]/vendorservices/[vendorserviceId]/edit',
    '/requirements/[id]/purchase-order/[vendorserviceId]/vendorservices/add',
    '/purchase-order/[id]/vendorservices/[vendorserviceId]/edit',
    '/purchase-order/[id]/vendorservices',
    '/gstNumbers/[gstId]/edit',
    '/gstNumbers',
    '/gstNumbers/new',
    '/users',
    '/users/new',
    '/users/[id]/edit',
  ];
  return !unAccessibleRoutes.includes(route);
};
