/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import capitalize from '@components/utils/capitalize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  removeClientRequiremenetFilter,
  removeRequiremenetFilter,
} from '@services/filters.service';
import { getAuth, removeAuth } from '@services/identity.service';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import ChangePassword from './ChangePassword';
import Toast from './Toast';

const Header = (props) => {
  const toastRef = useRef();
  const router = useRouter();
  const [profileClicked, setProfileClicked] = useState(false);
  const [auth, setAuth] = useState();
  const wrapperRef = useRef(null);
  const [changePasswordActive, setChangePasswordActive] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    if (auth) {
      setAuth(auth);
    }
  }, []);
  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setProfileClicked(false);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false);
    return () => {
      document.removeEventListener('click', handleClickOutside, false);
    };
  }, []);
  const closeEdit = () => {
    setChangePasswordActive(false);
  };
  return (
    <>
      {changePasswordActive && (
        <ChangePassword
          closeEdit={closeEdit}
          auth={auth}
          setToast={toastRef.current.setToast}
        />
      )}
      <Toast ref={toastRef}></Toast>
      <div className='w-full h-10 p-2  flex text-center justify-between lg:justify-between items-center fixed z-30 top-0 bg-white border'>
        <div className='flex'>
          <button
            className='cursor-pointer text-xl leading-none px-3 py-1  border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none'
            type='button'
            onClick={() => props.handleMenu()}
          >
            <FontAwesomeIcon
              icon='bars'
              className='text-dark-blue'
            />
           
          </button>
          <div className='text-black ml-4'>
            {/* {router.pathname.split('/')[1].toUpperCase()} */}
          </div>
        </div>
        <div className='text-center block lg:hidden'>
          <Image
            className='logo'
            src='/WHNowLogo1.png'
            quality={100}
            height={35}
            width={40}
          ></Image>
        </div>
        <div
          className='text-right pr-1 sm:pr-10 flex flex-col items-center justify-center '
          ref={wrapperRef}
        >
          <div
            className='cursor-pointer'
            onClick={() => {
              setProfileClicked(!profileClicked);
            }}
          >
            <span>
              {auth &&
                `${
                  auth?.name?.capitalize() + ' ' + `(${auth?.role})` || 'N/A'
                }`}
            </span>
            <FontAwesomeIcon
              icon={profileClicked ? `caret-up` : `caret-down`}
              className='font-thin text-sm mx-2'
            />
          </div>
        </div>
        {profileClicked && (
          <div className='absolute flex flex-col top-9 right-12 bg-white border rounded-md px-2'>
            <button
              className='text-center p-1 border-b w-full'
              onClick={() => {
                setChangePasswordActive(true);
              }}
            >
              Change Password
            </button>
            <button
              className='text-center p-1 '
              onClick={() => {
                removeAuth();
                removeRequiremenetFilter();
                removeClientRequiremenetFilter();
                router.push('/');
              }}
            >
              Logout
            </button>
          </div>
        )}
      </div>
    </>
  );
};
export default Header;
